import {
  Authenticator, defaultDarkModeOverride, Divider, Text, ThemeProvider, View
} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import '@fontsource/inter';
import { useState } from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import SubscriptionPage from './pages/SubscriptionPage';
import HomePage from './pages/HomePage';
import CreateAccountPage from './pages/CreateAccountPage';
import TutorialPage from './pages/TutorialPage';

function App() {
  const [colorMode, setColorMode] = useState('system')
  const theme = {
    name: 'my-theme',
    overrides: [defaultDarkModeOverride],
  };
  return (
    <Router>
      <Routes>
        <Route path='/' element={<SubscriptionPage/>}/>
        <Route path='/create-account' element={<CreateAccountPage/>} />
      </Routes>
    </Router>
  );
}

export default App;